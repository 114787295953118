import { supabase } from "@plugins/supabase"
import { SETUP_LEAGUETABLE_STATE, SET_LEAGUEDATA } from "@store/mutation-types"

const getDefaultState = () => {
  return {
    leagueData: [],
  }
}

// initial state
const state = getDefaultState()

const getters = {
  leagueTable: (state) => state.leagueData,
}

const actions = {
  resetLeagueTableState({ commit }) {
    commit("SETUP_LEAGUETABLE_STATE")
  },
  async fetchLeagueData({ commit, rootGetters }, payload) {
    if (!payload.region) {
      rootGetters.currentFirm[0]?.firmRegion
        ? (payload.region = rootGetters.currentFirm?.firmRegion)
        : null
    }
    const { data } = await supabase.from("api_firm").select(`*,
      api_branch(*)
      `)
    let tableData = data
      .map((item) => ({
        firmName: item.firmName,
        firmRegion: item.firmRegion,
        firmTotal: item.firmTotal,
      }))
      .sort((a, b) => {
        return b.firmTotal - a.firmTotal
      })

    tableData = tableData.filter((item) => item.firmTotal != 0)
    if (payload.region != "National league table") {
      tableData = tableData.filter((item) => item.firmRegion == payload.region)
    }
    for (let i = 0; i < tableData.length; i++) {
      tableData[i].firmPosition = i + 1
    }
    if (payload.firm) {
      tableData = tableData.filter((item) => item.firmName == payload.firm)
    }
    commit("SET_LEAGUEDATA", tableData)
  },
}

const mutations = {
  [SETUP_LEAGUETABLE_STATE]: (state) => Object.assign(state, getDefaultState()),
  [SET_LEAGUEDATA]: (state, leagueData) => (state.leagueData = leagueData),
}

export default {
  state,
  getters,
  actions,
  mutations,
}
