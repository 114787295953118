import router from "@/router"
import { http } from "@plugins/axios"
import Vue from "vue"
import {
  SETUP_AUTH_STATE,
  SET_LOGIN_CREDS,
  SET_AUTH_TIME,
  SET_HASH,
  SET_LOGIN_STEP,
  SET_VALID_EMAIL,
  SET_OTP,
  SET_SECRET,
  SET_TOKEN,
  SET_USER,
  SET_VERIFY_FAIL,
} from "@store/mutation-types"

const getDefaultState = () => {
  return {
    secret: null,
    token: null,
    authTime: "",
    otp: null,
    user: null,
    hash: null,
    loginStep: 1,
    verifyFail: false,
    validEmail: false,
    loginCreds: {
      email: "",
      password: "",
    },
  }
}

// initial state
const state = getDefaultState()

const getters = {
  isAuthenticated: (state) => state.token,
  secret: (state) => state.secret,
  getUser: (state) => state.user,
  getHash: (state) => state.hash,
}

const actions = {
  resetAuthState({ commit }) {
    commit("SETUP_AUTH_STATE")
  },
  createHash({ commit }, length) {
    let hash = ""
    let characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"
    let charactersLength = characters.length
    for (let i = 0; i < length; i++) {
      hash += characters.charAt(Math.floor(Math.random() * charactersLength))
    }
    commit("SET_HASH", hash)
  },
  async login({ dispatch, commit }, credentials) {
    await http
      .post("login/", credentials)
      .then((response) => {
        if (response.data) {
          if (response.data["access_token"]) {
            const now = new Date().getTime()
            commit("SET_AUTH_TIME", now)
            commit("SET_TOKEN", response.data["access_token"])
            dispatch("getUserData")
            commit("SET_LOGIN_CREDS", {
              email: "",
              password: "",
            })
          } else if (response.data["Secret"]) {
            commit("SET_SECRET", response.data["Secret"])
          }
        }
        return true
      })
      .catch((error) => {
        console.log(error)
        Vue.prototype.$toast.error(
          "There has been an error logging onto the system."
        )
      })
  },
  async verify({ dispatch, commit, state }, data) {
    await http
      .post("verify/", {
        email: data.email,
        otp: data.passcode,
        secret: state.secret,
      })
      .then((response) => {
        const now = new Date().getTime()
        commit("SET_AUTH_TIME", now)
        commit("SET_TOKEN", response.data["access_token"])
        dispatch("getUserData")
        commit("SET_VERIFY_FAIL", false)
      })
      .catch((error) => {
        console.log(error)
        commit("SET_VERIFY_FAIL", true)
      })
  },

  logout({ dispatch, commit }, msg) {
    commit("SET_TOKEN", null)
    router
      .push({
        name: "Home",
      })
      .catch(() => {})
    dispatch("resetAuthState")
    dispatch("resetActivityState")
    dispatch("resetBookingState")
    dispatch("resetClientState")
    dispatch("resetEnquiryState")
    dispatch("resetSettingsState")
    dispatch("resetSetupState")
    dispatch("resetLeagueTableState")
    dispatch("resetDonationState")

    if (msg == "logout") {
      Vue.prototype.$toast.info("You are now logged out!")
    }
    if (msg == "expired") {
      Vue.prototype.$toast.error(
        "You are being signed out of the system as you're session has expired. To  continue using the Will Aid booking system please do login again."
      )
    }
    if (msg == "error") {
      Vue.prototype.$toast.error(
        "There has been an error on the system so you are being automatically signed out. To  continue using the Will Aid booking system please do login again."
      )
    }
    if (msg == "unautorised") {
      Vue.prototype.$toast.error(
        "You are unauthorised to view this page. Please login using a valid email and password"
      )
    }
  },

  async userCheck({ commit }, credentials) {
    await http
      .get(`user-check/?email=${credentials.email}`)
      .then((response) => {
        if (response.data.exists) {
          commit("SET_VALID_EMAIL", true)
          commit("SET_LOGIN_CREDS", {
            email: credentials.email,
            password: credentials.password,
          })
        } else {
          commit("SET_VALID_EMAIL", false)
          Vue.prototype.$toast.warning(
            "Sorry we can't find a user with this email."
          )
        }
      })
  },

  async getUserData({ commit }) {
    await http
      .get("user/")
      .then((response) => {
        commit("SET_USER", response.data[0])
      })
      .catch((error) => {
        console.log(error)
        return error
      })
  },

  async recover({ dispatch }, credentials) {
    await http
      .post("rest-auth/password/reset/", credentials)
      .then((response) => {
        dispatch("userCheck", credentials)
        Vue.prototype.$toast.success(response.data["detail"])
      })
      .catch((error) => {
        Vue.prototype.$toast.error(
          "Sorry but there is no user with that email on our system."
        )
        return error
      })
  },
}

const mutations = {
  [SETUP_AUTH_STATE]: (state) => Object.assign(state, getDefaultState()),
  [SET_LOGIN_CREDS]: (state, loginCreds) => (state.loginCreds = loginCreds),
  [SET_VALID_EMAIL]: (state, validEmail) => (state.validEmail = validEmail),
  [SET_VALID_EMAIL]: (state, validEmail) => (state.validEmail = validEmail),
  [SET_TOKEN]: (state, token) => (state.token = token),
  [SET_AUTH_TIME]: (state, authTime) => (state.authTime = authTime),
  [SET_SECRET]: (state, secret) => (state.secret = secret),
  [SET_OTP]: (state, otp) => (state.otp = otp),
  [SET_USER]: (state, user) => (state.user = user),
  [SET_HASH]: (state, hash) => (state.hash = hash),
  [SET_LOGIN_STEP]: (state, loginStep) => (state.loginStep = loginStep),
  [SET_VERIFY_FAIL]: (state, verifyFail) => (state.verifyFail = verifyFail),
}

export default {
  state,
  getters,
  actions,
  mutations,
}
