import Vue from "vue"
import VueRouter from "vue-router"
import store from "@/store"

Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import(/* webpackChunkName: "home" */ "@views/Home.vue"),
    meta: { guest: true },
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: () =>
      import(/* webpackChunkName: "dashboard" */ "@views/Dashboard.vue"),
    meta: {
      layout: "DashboardLayout",
      requiresAuth: true,
    },
  },
  {
    path: "/booking",
    name: "Booking",
    component: () =>
      import(/* webpackChunkName: "diary" */ "@views/BookingSystem.vue"),
    props: true,
    meta: {
      layout: "DashboardLayout",
      requiresAuth: true,
    },
  },
  {
    path: "/appointments",
    name: "AppointmentsView",
    component: () =>
      import(
        /* webpackChunkName: "appointments" */ "@views/AppointmentsInbox.vue"
      ),
    meta: {
      layout: "DashboardLayout",
      requiresAuth: true,
    },
  },
  {
    path: "/enquiries",
    name: "Enquiries",
    component: () =>
      import(/* webpackChunkName: "enquiries" */ "@views/EnquiriesInbox.vue"),
    meta: {
      layout: "DashboardLayout",
      requiresAuth: true,
    },
  },
  {
    path: "/faqs",
    name: "FAQs",
    component: () => import(/* webpackChunkName: "faqs" */ "@views/Faq.vue"),
    meta: {
      layout: "DashboardLayout",
      requiresAuth: true,
    },
  },
  {
    path: "/notifications",
    name: "Notifications",
    component: () =>
      import(
        /* webpackChunkName: "notifications" */ "@views/Notifications.vue"
      ),
    meta: {
      reload: true,
      layout: "DashboardLayout",
      requiresAuth: true,
    },
  },
  {
    path: "/resource-centre",
    name: "ResourceCentre",
    component: () =>
      import(/* webpackChunkName: "resources" */ "@views/ResourceCentre.vue"),
    meta: {
      layout: "DashboardLayout",
      requiresAuth: true,
    },
  },
  {
    path: "/league-table",
    name: "LeagueTable",
    component: () =>
      import(/* webpackChunkName: "league" */ "@views/LeagueTable.vue"),
    meta: {
      layout: "DashboardLayout",
      requiresAuth: true,
    },
  },
  {
    path: "/booking-system-settings",
    name: "BookingSettings",
    component: () =>
      import(/* webpackChunkName: "settings" */ "@views/BookingSettings.vue"),
    props: true,
    meta: {
      layout: "DashboardLayout",
      requiresAuth: true,
    },
  },
  {
    path: "/donate",
    name: "DonateForm",
    component: () =>
      import(/* webpackChunkName: "donate" */ "@views/Donate.vue"),
    beforeEnter: (to, from, next) => {
      store.dispatch("resetDonationState")
      next()
    },
    meta: {
      layout: "DashboardLayout",
      requiresAuth: true,
    },
  },
  {
    path: "/:catchAll(.*)",
    component: () => import(/* webpackChunkName: "404" */ "@views/404.vue"),
    name: "404",
  },
]

const router = new VueRouter({
  mode: "history",
  routes,
})

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.getters.isAuthenticated) {
      next()
      return
    }
    next("/")
  } else {
    next()
  }
})

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.guest)) {
    if (store.getters.isAuthenticated) {
      next("/dashboard")
      return
    }
    next()
  } else {
    next()
  }
})

export default router
