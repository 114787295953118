import Vue from "vue"
import Toast, { POSITION } from "vue-toastification"
// Import the CSS or use your own!
import "vue-toastification/dist/index.css"

const filterBeforeCreate = (toast, toasts) => {
  if (toasts.filter((t) => t.type === toast.type).length !== 0) {
    // Returning false discards the toast
    return false
  }
  // You can modify the toast if you want
  return toast
}

const options = {
  // You can set your default options here
  timeout: 3500,
  position: POSITION.BOTTOM_RIGHT,
  filterBeforeCreate,
}

Vue.use(Toast, options)

export default Toast
