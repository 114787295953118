import { http } from "@plugins/axios"
import { supabase } from "@plugins/supabase"
import {
  SETUP_ENQUIRY_STATE,
  SET_ADD_ENQUIRY,
  SET_EDIT_ENQUIRY,
  SET_ENQUIRIES,
  SET_ENQUIRY_FILTER,
  SET_REMOVE_ENQUIRY,
  SET_ENQUIRY_MODAL,
} from "@store/mutation-types"

const formatEnquiry = (item) => {
  if (item.enquiryDonated == true) {
    item.enquiryStatus = "Donated"
  }
  return {
    enquiryClient: {
      clientId: item.api_client.clientId,
      clientFullName:
        item.api_client.clientFirstName + " " + item.api_client.clientLastName,
      clientFirstName: item.api_client.clientFirstName,
      clientLastName: item.api_client.clientLastName,
      clientEmail: item.api_client.clientEmail,
      clientPhone: item.api_client.clientPhone,
      clientAddress1: item.api_client.clientAddress1,
      clientAddress2: item.api_client.clientAddress2,
      clientTown: item.api_client.clientTown,
      clientPostcode: item.api_client.clientPostcode,
      clientCountry: item.api_client.clientCountry,
      clientRemote: item.api_client.clientRemote,
      clientType: item.api_client.clientType,
    },
    enquiryBranch: {
      branchPostcode: item.api_branch.branchPostcode,
    },
    enquiryFirm: item.api_firm.firmId,
    ...item,
  }
}

const getDefaultState = () => {
  return {
    enquiries: [],
    filter: null,
    enquiryModal: { display: false, form: false, mode: "add" },
  }
}

// initial state
const state = getDefaultState()

const getters = {
  allEnquiries: (state) => state.enquiries,
  enqFilter: (state) => state.filter,
  enqModal: (state) => state.enquiryModal,
}

const actions = {
  resetEnquiryState({ commit }) {
    commit("SETUP_ENQUIRY_STATE")
  },
  async fetchEnquiries({ commit, rootGetters }) {
    let tableData = []
    if (rootGetters.currentFirm) {
      const firm = rootGetters.currentFirm?.firmId
      let results = []

      if (this.state.enquiries.filter != null) {
        const { data } = await supabase
          .from("api_enquiry")
          .select(
            `*, api_client(*), api_branch(branchPostcode), api_firm(firmId)`
          )
          .eq(`enquiryFirm_id`, firm)
          .eq(`enquiryStatus`, this.state.enquiries.filter)
        results = data
      } else {
        const { data } = await supabase
          .from("api_enquiry")
          .select(
            `*, api_client(*), api_branch(branchPostcode), api_firm(firmId)`
          )
          .eq(`enquiryFirm_id`, firm)
          .order(`enquiryCreateDate`, { ascending: false })

        results = data
        tableData = results.map((item) => formatEnquiry(item))
      }
    }

    commit("SET_ENQUIRIES", tableData)
  },
  async addEnquiry({ commit }, enquiry) {
    const { client } = enquiry
    delete enquiry.client
    enquiry.enquiryPhone = client.clientPhone
    await http
      .post("clients/", client)
      .then(async (response) => {
        enquiry.enquiryClient = response.data.clientId
        enquiry.enquiryPhone = response.data.clientPhone
        await http.post("user/enquiries/", enquiry).then(async (response) => {
          await http
            .get(`user/enquiries/${response.data.enquiryId}/`)
            .then(function (response) {
              commit("SET_ADD_ENQUIRY", response.data)
            })
        })
      })
      .catch(function (error) {
        console.log(error.reponse.data)
      })
  },
  async updateEnquiry({ dispatch }, update) {
    const { client } = update
    delete update.client
    if (client) {
      await http.patch(`clients/${client.clientId}`, client)
    }
    if (client) {
      update.enquiryPhone = client.clientPhone
    }
    await http
      .put(`user/enquiries/${update.enquiryId}/`, update)
      .then(function () {
        http.get(`user/enquiries/${update.enquiryId}/`).then(function () {
          // commit("SET_EDIT_ENQUIRY", response.data)
          dispatch("fetchEnquiries")
        })
      })
      .catch(function (error) {
        console.log(error.reponse.data)
      })
  },
  async deleteEnquiry({ commit }, id) {
    await http.delete(`user/enquiries/${id}`)
    commit("SET_REMOVE_ENQUIRY", id)
  },
  async filterEnquiries({ commit, rootGetters }, e) {
    const firm = rootGetters.currentFirm.firmId
    let results = []
    //let filter = e.target.innerText.toLowerCase();
    //let e_filter = filter.charAt(0).toUpperCase() + filter.slice(1);

    if (e === null) {
      let { data } = await supabase
        .from("api_enquiry")
        .select(
          `*, api_client(*), api_branch(branchPostcode), api_firm(firmId)`
        )
        .eq(`enquiryFirm_id`, firm)
        .order(`enquiryCreateDate`, { ascending: false })
      results = data
    } else if (e === "Donated") {
      let { data } = await supabase
        .from("api_enquiry")
        .select(
          `*, api_client(*), api_branch(branchPostcode), api_firm(firmId)`
        )
        .eq(`enquiryFirm_id`, firm)
        .eq(`enquiryDonated`, true)
        .order(`enquiryCreateDate`, { ascending: false })
      results = data
    } else {
      let { data } = await supabase
        .from("api_enquiry")
        .select(
          `*, api_client(*), api_branch(branchPostcode), api_firm(firmId)`
        )
        .eq(`enquiryFirm_id`, firm)
        .eq(`enquiryStatus`, e)
        .neq(`enquiryDonated`, true)
        .order(`enquiryCreateDate`, { ascending: false })
      results = data
    }
    let tableData = results.map((item) => formatEnquiry(item))

    commit("SET_ENQUIRIES", tableData)

    // const response = await http.get(`user/enquiries/?Status=${e_filter}`);

    // commit("SET_ENQUIRIES", response.data["results"]);

    commit("SET_ENQUIRY_FILTER", e)
  },
}

const mutations = {
  [SETUP_ENQUIRY_STATE]: (state) => Object.assign(state, getDefaultState()),
  [SET_ENQUIRIES]: (state, enquiries) => (state.enquiries = enquiries),
  [SET_ENQUIRY_FILTER]: (state, e_filter) => (state.filter = e_filter),
  [SET_ADD_ENQUIRY]: (state, enquiry) => state.enquiries.unshift(enquiry),
  [SET_REMOVE_ENQUIRY]: (state, id) =>
    (state.enquiries = state.enquiries.filter((enquiry) => enquiry.id !== id)),
  [SET_EDIT_ENQUIRY]: (state, update) => {
    let index = state.enquiries.findIndex(
      (enquiry) => enquiry.enquiryId === update.enquiryId
    )
    if (index !== -1) {
      state.enquiries.splice(index, 1, update)
    }
  },
  [SET_ENQUIRY_MODAL]: (state, enquiryModal) =>
    (state.enquiryModal = enquiryModal),
}

export default {
  state,
  getters,
  actions,
  mutations,
}
