import { http } from "@plugins/axios"
import {
  SETUP_NOTIFICATION_STATE,
  SET_ADD_NOTIFICATION,
  SET_EDIT_NOTIFICATION,
  SET_NOTIFICATIONS,
  SET_LATEST_NOTIFICATIONS,
  SET_NOTIFICATION_FILTER,
  SET_DISMISS_NOTIFICATION,
} from "@store/mutation-types"

const getDefaultState = () => {
  return {
    notifications: [],
    latestNotifications: [],
    filter: null,
  }
}

// initial state
const state = getDefaultState()

const getters = {
  allNotifications: (state) => state.notifications,
  latestNotifications: (state) => state.latestNotifications,
  notificationFilter: (state) => state.filter,
}

const actions = {
  resetNotificationState({ commit }) {
    commit("SETUP_NOTIFICATION_STATE")
  },
  async fetchNotifications({ commit }) {
    if (state.filter != null) {
      await http
        .get(`user/notifications/?Type=${state.filter}`)
        .then(function (response) {
          commit("SET_NOTIFICATIONS", response.data["results"])
        })
        .catch(function (error) {
          console.log(error.reponse.data)
        })
    } else {
      await http
        .get(`user/notifications/`)
        .then(function (response) {
          commit(
            "SET_LATEST_NOTIFICATIONS",
            response.data["results"].filter(
              (notification) => notification.notificationStreamStatus == "new"
            )
          )
          commit("SET_NOTIFICATIONS", response.data["results"])
        })
        .catch(function (error) {
          console.log(error.reponse.data)
        })
    }
  },
  fetchWSNotifications({ commit, rootState, state }) {
    const firm = rootState.setup.firm[0]?.firmId
    const { token } = rootState.auth
    const socket = new WebSocket(
      `${process.env.VUE_APP_WS_URL}notifications/${firm}/?token=${token}`
    )
    socket.onmessage = function (event) {
      let latest_notifications = []
      if (state.latestNotifications) {
        latest_notifications = state.latestNotifications
      }
      let data = JSON.parse(event.data)
      if (data.notificationStreamFirm == firm) {
        latest_notifications.unshift(data)
      }
      commit("SET_LATEST_NOTIFICATIONS", latest_notifications)
    }
  },
  removeWSNotification({ commit, rootState }, data) {
    const firm = rootState.setup.firm[0]?.firmId
    const token = rootState.auth.token
    const socket = new WebSocket(
      `${process.env.VUE_APP_WS_URL}notifications/${firm}/?token=${token}`
    )

    data.message.data.notificationStreamFirm = firm
    socket.onopen = () => socket.send(JSON.stringify(data))
    commit("SET_DISMISS_NOTIFICATION", data.message.data.notificationStreamId)
  },
  async addNotification({ commit }, notification) {
    const response = await http.post("user/notifications/", notification)

    commit("SET_ADD_NOTIFICATION", response.data)
  },
  updateWSNotification({ commit, rootState }, data) {
    const firm = rootState.setup.firm[0]?.firmId
    const token = rootState.auth.token
    const socket = new WebSocket(
      `${process.env.VUE_APP_WS_URL}notifications/${firm}/?token=${token}`
    )

    data.message.data.notificationStreamFirm = firm
    socket.onopen = () => socket.send(JSON.stringify(data))
    commit("SET_DISMISS_NOTIFICATION", data.message.data.notificationStreamId)
  },
  async deleteNotification({ commit }, id) {
    await http.delete(`user/notifications/${id}`)
    commit("SET_DISMISS_NOTIFICATION", id)
  },
  async filterNotifications({ commit }, e) {
    let e_filter = e.target.innerText
    if (e_filter != "Help") {
      e_filter = e_filter.slice(0, -1)
    }

    if (e_filter === "All") {
      e_filter = ""
    }

    const response = await http.get(`user/notifications/?type=${e_filter}`)

    commit("SET_NOTIFICATIONS", response.data["results"])
    commit("SET_NOTIFICATION_FILTER", e_filter)
  },
}

const mutations = {
  [SETUP_NOTIFICATION_STATE]: (state) =>
    Object.assign(state, getDefaultState()),
  [SET_NOTIFICATIONS]: (state, notifications) =>
    (state.notifications = notifications),
  [SET_LATEST_NOTIFICATIONS]: (state, latestNotifications) =>
    (state.latestNotifications = latestNotifications),
  [SET_NOTIFICATION_FILTER]: (state, e_filter) => (state.filter = e_filter),
  [SET_ADD_NOTIFICATION]: (state, notification) =>
    state.notifications.unshift(notification),
  [SET_DISMISS_NOTIFICATION]: (state, notificationStreamId) =>
    (state.latestNotifications = state.latestNotifications.filter(
      (notification) =>
        notification.notificationStreamId !== notificationStreamId
    )),
  [SET_EDIT_NOTIFICATION]: (state, update) => {
    let index = state.enquiries.findIndex(
      (notification) =>
        notification.notificationStreamId === update.notificationStreamId
    )
    if (index !== -1) {
      state.notifications.splice(index, 1, update)
    }
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
