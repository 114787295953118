import { http } from "@plugins/axios"
import {
  SETUP_CLIENT_STATE,
  SET_CLIENT,
  SET_CLIENTS,
} from "@store/mutation-types"

const getDefaultState = () => {
  return {
    clients: {},
  }
}

// initial state
const state = getDefaultState()

const getters = {
  allClients: (state) => state.clients,
  client: (state) => state.client,
}

const actions = {
  resetClientState({ commit }) {
    commit("SETUP_CLIENT_STATE")
  },
  async fetchClients({ commit }) {
    const response = await http.get("clients/")
    commit("SET_CLIENTS", response.data)
  },
  async fetchClient({ commit }, client) {
    const response = await http.get(`clients/${client.clientId}`)
    commit("SET_CLIENT", response.data)
  },
  async updateClient({ commit }, client) {
    const response = await http.patch(`clients/${client.clientId}`, client)
    commit("SET_CLIENT", response.data)
  },
}

const mutations = {
  [SETUP_CLIENT_STATE]: (state) => Object.assign(state, getDefaultState()),
  [SET_CLIENTS]: (state, clients) => (state.clients = clients),
  [SET_CLIENT]: (state, client) => (state.client = client),
}

export default {
  state,
  getters,
  actions,
  mutations,
}
