import Vue from "vue"
import axios from "axios"
import store from "../store"

let config = {
  baseURL: process.env.VUE_APP_BASE_URL,
  timeout: 20000,
  withCredentials: true,
  xsrfCookieName: "csrftoken",
  xsrfHeaderName: "X-CSRFToken",
  headers: {
    "X-Api-Key": process.env.VUE_APP_KEY,
    "Content-Type": "application/json",
    Accept: "application/json",
    "Cache-Control": "no-cache",
    Pragma: "no-cache",
    Expires: "0",
  },
}

export const http = axios.create(config)

const getAuthToken = () => {
  const store = JSON.parse(localStorage.getItem("vuex"))
  if (store.auth.token !== null) {
    return "Bearer " + store.auth.token
  }
}

const authInterceptor = (config) => {
  config.headers["Authorization"] = getAuthToken()
  return config
}

http.interceptors.request.use(authInterceptor)

http.interceptors.response.use(undefined, function axiosRetryInterceptor(err) {
  if (!err.response) {
    if (store.getters.isAuthenticated) {
      store.dispatch("logout", "error")
    }
  } else if (err.response.status === 401) {
    if (store.getters.isAuthenticated) {
      store.dispatch("logout", "expired")
    }
  } else if (store.getters.isAuthenticated) {
    store.dispatch("logout", "error")
  }
  return Promise.reject(err)
})

const httpServicePlugin = {
  install(Vue) {
    Vue.prototype.$axios = http
  },
}

Vue.use(httpServicePlugin)

export default httpServicePlugin
