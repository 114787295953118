import { http } from "@plugins/axios"
import {
  SETUP_RESOURCE_STATE,
  SET_RESOURCES,
  SET_RESOURCE_FILTER,
} from "@store/mutation-types"

const getDefaultState = () => {
  return {
    resources: [],
    filter: null,
  }
}

// initial state
const state = getDefaultState()

const getters = {
  allResources: (state) => state.resources,
  catFilter: (state) => state.filter,
}

const actions = {
  async fetchResources({ commit }) {
    await http
      .get("resources/?resourceStatus=live")
      .then(function(response) {
        commit(SET_RESOURCES, response.data.results)
      })
      .catch(function(error) {
        console.log(error)
      })
  },
  async filterResources({ commit }, e) {
    let filter = e.target.innerText

    if (filter === "All") {
      filter = ""
    }

    const response = await http.get(
      `resources/?resourceStatus=live&resourceCategory__name=${filter}`
    )

    commit("SET_RESOURCES", response.data["results"])
    commit("SET_RESOURCE_FILTER", filter)
  },
}

const mutations = {
  [SETUP_RESOURCE_STATE]: (state) => {
    Object.assign(state, getDefaultState())
  },
  [SET_RESOURCES]: (state, resources) => (state.resources = resources),
  [SET_RESOURCE_FILTER]: (state, e_filter) => (state.filter = e_filter),
}

export default {
  state,
  getters,
  actions,
  mutations,
}
