import Vue from "vue"
import Vuetify from "vuetify/lib"

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    options: { customProperties: true },
    themes: {
      light: {
        brand: "#881730",
        primary: "#000",
        aqua: "#86FAEE",
        aquaFade: "#D8FEF1",
        grey: "#E6E6E6",
        greyFade: "#FAFAFA",
        greyDark: "#9a9a9a",
        secondary: "#424b5a",
        blue: "#6690FF",
        blueFade: "#D6E4FF",
        orange: "#FCB346",
        green: "#7be382",
        red: "#FD795E",
        redFade: "#FFC3AD",
        accent: "#c2d1d9",
        error: "#FC3C2A",
        success: "#94DD16",
      },
    },
  },
})
