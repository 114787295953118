<template>
  <v-row align="center" justify="center">
    <v-col cols="11" sm="8" md="4">
      <v-alert border="right" colored-border type="error" elevation="2">
        <h3>
          It looks like you've lost your connection to the internet. Please
          check your connection and try again.
        </h3>
      </v-alert>
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: "OfflineWarning",
}
</script>
