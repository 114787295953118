import { http } from "@plugins/axios"
import {
  SETUP_SETTING_STATE,
  SET_BRANCH_SETTINGS,
  SET_DIARY_ONLINE,
  SET_DIARY_SETTINGS,
  SET_DIARY_STATUS,
  SET_DIARY_SYNC,
  SET_EMAIL_FOLLOWUP,
  SET_EMAIL_MESSAGE,
  SET_EMAIL_SETTINGS,
  SET_EMAIL_SUBJECT,
} from "@store/mutation-types"

const getDefaultState = () => {
  return {
    BranchSettings: {},
    DiarySettings: {},
    EmailSettings: {},
  }
}

// initial state
const state = getDefaultState()

const getters = {
  branchSettings: (state) => state.BranchSettings,
  branchDiarySettings: (state) => state.DiarySettings,
  branchEmailSettings: (state) => state.EmailSettings,
  branchNotificationSettings: (state) => state.NotificationSettings,
  branchCal: (state) => state.BranchSettings.branchDiaryURL,
}

const actions = {
  resetSettingsState({ commit }) {
    commit("SETUP_SETTING_STATE")
  },
  async fetchSettings({ commit }, id) {
    await http
      .get(`branches/settings/${id}/`)
      .then(function(response) {
        commit("SET_BRANCH_SETTINGS", response.data)
      })
      .catch(function(error) {
        console.log(error.reponse.data)
      })
  },
  async fetchDiarySettings({ commit }, id) {
    await http
      .get(`branches/settings/${id}/`)
      .then(function(response) {
        commit(
          "SET_DIARY_SETTINGS",
          response.data["branchSettings"]["diarySettings"]
        )
      })
      .catch(function(error) {
        console.log(error.reponse.data)
      })
  },
  async fetchEmailSettings({ commit }, id) {
    await http
      .get(`branches/settings/${id}/`)
      .then(function(response) {
        commit(
          "SET_EMAIL_SETTINGS",
          response.data["branchSettings"]["emailSettings"]
        )
      })
      .catch(function(error) {
        console.log(error.reponse.data)
      })
  },
  async updateSettings({ state, commit }, update) {
    await http
      .patch(`branches/settings/${update.id}/`, {
        branchSettings: {
          diarySettings: {
            status: state.DiarySettings.status,
            online: state.DiarySettings.online,
            cancellationNotice: state.DiarySettings.cancellationNotice,
            calendarSync: state.DiarySettings.calendarSync,
          },
          emailSettings: {
            message: state.EmailSettings.message,
            subject: state.EmailSettings.subject,
            followupEmail: state.EmailSettings.followupEmail,
          },
        },
      })
      .then(function(response) {
        commit("SET_BRANCH_SETTINGS", response.data)
        commit(
          "SET_DIARY_SETTINGS",
          response.data["branchSettings"]["diarySettings"]
        )
        commit(
          "SET_EMAIL_SETTINGS",
          response.data["branchSettings"]["emailSettings"]
        )
      })
      .catch(function(error) {
        console.log(error.reponse.data)
      })
  },
}

const mutations = {
  [SETUP_SETTING_STATE]: (state) => Object.assign(state, getDefaultState()),
  [SET_BRANCH_SETTINGS]: (state, BranchSettings) =>
    (state.BranchSettings = BranchSettings),
  [SET_DIARY_SETTINGS]: (state, DiarySettings) =>
    (state.DiarySettings = DiarySettings),
  [SET_DIARY_STATUS]: (state, status) =>
    (state.DiarySettings = { ...state.DiarySettings, status }),
  [SET_DIARY_ONLINE]: (state, online) =>
    (state.DiarySettings = { ...state.DiarySettings, online }),
  [SET_DIARY_SYNC]: (state, calendarSync) =>
    (state.DiarySettings = { ...state.DiarySettings, calendarSync }),
  [SET_EMAIL_SETTINGS]: (state, EmailSettings) =>
    (state.EmailSettings = EmailSettings),
  [SET_EMAIL_FOLLOWUP]: (state, followupEmail) =>
    (state.EmailSettings = { ...state.EmailSettings, followupEmail }),
  [SET_EMAIL_SUBJECT]: (state, subject) =>
    (state.EmailSettings = { ...state.EmailSettings, subject }),
  [SET_EMAIL_MESSAGE]: (state, message) =>
    (state.EmailSettings = { ...state.EmailSettings, message }),
}

export default {
  state,
  getters,
  actions,
  mutations,
}
