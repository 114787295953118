import { http } from "@plugins/axios"
import {
  SETUP_BRANCH_STATE,
  SETUP_FIRM_STATE,
  SETUP_STATE,
  SET_BRANCHES,
  SET_FIRM,
  SET_ERROR,
  SET_MAINTENANCE,
  SET_SOURCE,
} from "@store/mutation-types"

const getDefaultState = () => {
  return {
    firm: [],
    branches: [],
    source: [],
    maintenance: true,
  }
}

// initial state
const state = getDefaultState()

const getters = {
  currentFirm: (state) => state.firm[0],
  firmBranches: (state) => state.branches,
  allSources: (state) => state.source,
}

const actions = {
  resetSetupState({ commit }) {
    commit("SETUP_STATE")
  },

  transmitError({ commit }, errorMessage) {
    commit("SET_ERROR", errorMessage)
  },

  async fetchFirm({ commit }) {
    await http
      .get("user/firms/")
      .then(function (response) {
        commit("SET_FIRM", response.data)
      })
      .catch(function (error) {
        console.log(error.reponse.data)
      })
  },

  async fetchBranches({ commit }) {
    await http
      .get("user/branches/")
      .then(function (response) {
        commit("SET_BRANCHES", response.data)
      })
      .catch(function (error) {
        console.log(error.response.data["firmBranches"])
      })
  },
  async fetchSources({ commit }) {
    const response = await http.get("sources/")
    let { data } = response
    const sources = data.map((source) => source.sourceTitle)
    commit(SET_SOURCE, sources)
  },
}

const mutations = {
  [SETUP_STATE]: (state) => Object.assign(state, getDefaultState()),
  [SETUP_FIRM_STATE]: (state) => Object.assign(state, getDefaultState()),
  [SETUP_BRANCH_STATE]: (state) => Object.assign(state, getDefaultState()),
  [SET_FIRM]: (state, firm) => (state.firm = firm),
  [SET_BRANCHES]: (state, branches) => (state.branches = branches),
  [SET_ERROR]: (state, error) => (state.error = error),
  [SET_SOURCE]: (state, source) => (state.source = source),
  [SET_MAINTENANCE]: (state, maintenance) => (state.maintenance = maintenance),
}

export default {
  state,
  getters,
  actions,
  mutations,
}
