export const conversionRate = (total, converted) => {
  // console.log("total", total)
  // console.log("converted", converted)
  let conversion_long = 0
  if (total > 0) {
    conversion_long = Number(converted / total).toLocaleString(undefined, {
      style: "percent",
      minimumFractionDigits: 0,
    })
  }
  return `${conversion_long}`
}
