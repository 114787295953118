import { http } from "@plugins/axios"
import { SETUP_DONATION, SET_DONATION } from "@store/mutation-types"

const getDefaultState = () => {
  return {
    donation: {},
  }
}

// initial state
const state = getDefaultState()

const getters = {
  donation: (state) => state.donation,
  payment: (state) => state.donation.payment,
  status: (state) => state.donation.status,
}

const actions = {
  resetDonationState({ commit }) {
    commit("SETUP_DONATION")
  },
  async setDonation({ commit }, donation) {
    commit("SET_DONATION", donation)
  },
  async fetchDonationByStripeId({ commit }, id) {
    const response = await http.get(`donations/?donationStripeId=${id}`)
    commit("SET_DONATION", response.data["results"][0])
  },
  async addDonation({ commit }, donation) {
    if (!donation.donationClient) {
      donation.donationBilling.clientType = "Offline"
      await http
        .post("clients/", donation.donationBilling)
        .then(async (response) => {
          donation.donationClient = response.data.clientId
        })
        .catch(function (error) {
          console.log(error.reponse.data)
        })
      let enquiry = {}
      enquiry.enquiryClient = donation.donationClient
      enquiry.enquiryStatus = "Confirmed"
      enquiry.enquiryFirm = donation.donationFirm
      enquiry.enquiryBranch = donation.donationBranch

      await http
        .post("enquiries/", enquiry)
        .then(async (response) => {
          donation.donationEnquiry = response.data.enquiryId
        })
        .catch(function (error) {
          console.log(error.reponse.data)
        })
    }
    await http
      .post("donations/", donation)
      .then(async (response) => {
        commit("SET_DONATION", response.data)
      })
      .catch(function (error) {
        console.log(error.reponse.data)
      })
  },
  updateDonation({ commit }, donation) {
    http
      .patch(`donations/${donation.donationId}`, donation)
      .then(async (response) => {
        commit("SET_DONATION", response.data)
      })
      .catch(function (error) {
        console.log(error.reponse.data)
      })
  },
}

const mutations = {
  [SETUP_DONATION]: (state) => Object.assign(state, getDefaultState()),
  [SET_DONATION]: (state, donation) => (state.donation = donation),
}

export default {
  state,
  getters,
  actions,
  mutations,
}
