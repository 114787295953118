<template>
  <component :is="layout">
    <slot />
  </component>
</template>

<script>
export default {
  name: "LayoutWrapper",
  data: () => ({
    defaultLayout: "HomeLayout",
  }),
  computed: {
    layout() {
      const layout = this.$route.meta.layout || this.defaultLayout
      return () => import(`@/layouts/${layout}.vue`)
    },
  },
}
</script>
