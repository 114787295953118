import Vue from "vue"
import { http } from "@plugins/axios"
import dayjs from "dayjs"
import { orderBy } from "lodash"
import {
  SETUP_BOOKING_STATE,
  SET_ADD_BOOKING,
  SET_BOOKINGS,
  SET_BOOKINGS_UNFILTERED,
  SET_BOOKING_FILTER,
  SET_EDIT_BOOKING,
  SET_REMOVE_BOOKING,
} from "@store/mutation-types"

const formatBooking = (item) => {
  let st = item.bookingStartTime.slice(0, 5)
  let et = item.bookingEndTime.slice(0, 5)

  if (item.bookingClient == null) {
    item.bookingClient = { clientFullName: "Not booked online" }
    item.bookingPhone = "- - -"
  }

  switch (item.bookingStatus) {
    case "Booked":
      item.color = "secondary"
      break
    case "Cancelled":
      item.color = "greyDark"
      break
    default:
      item.color = "brand"
  }

  return {
    id: item.bookingId,
    name: item.bookingName,
    client: item.bookingClient,
    phone: item.bookingPhone,
    message: item.bookingMessage,
    branch: item.bookingBranch,
    firm: item.bookingFirm,
    start: item.bookingStartDate + " " + st,
    end: item.bookingEndDate + " " + et,
    editStartTime: st,
    editEndTime: et,
    date: item.bookingStartDate,
    time: st,
    complete: item.bookingComplete,
    booked: item.bookingComplete
      ? dayjs(item.bookingComplete).format("DD-MM-YYYY")
      : null,
    notes: item.bookingNotes,
    status: item.bookingStatus,
    color: item.color,
  }
}

const getDefaultState = () => {
  return {
    bookings: [],
    bookings_unfiltered: [],
    booking_filter: null,
  }
}

// initial state
const state = getDefaultState()

const getters = {
  allBookings: (state) => state.bookings,
  unfilteredBookings: (state) => state.bookings_unfiltered,
  bkFilter: (state) => state.booking_filter,
}

const actions = {
  resetBookingState({ commit }) {
    commit("SETUP_BOOKING_STATE")
  },
  async fetchAllBookings({ commit }, id) {
    let a_url = ""

    if (id) {
      a_url = "user/bookings/?bookingBranch=" + id
    } else {
      a_url = "user/bookings/"
    }
    await http
      .get(`${a_url}`)
      .then(function (response) {
        let events = []
        response.data["results"].forEach((item) => {
          events.push(formatBooking(item))
        })
        const newEvents = orderBy(events, ["complete"], ["desc"])
        commit("SET_BOOKINGS_UNFILTERED", newEvents)
      })
      .catch(function (error) {
        console.log(error)
      })
  },

  async fetchBookings({ commit }, id) {
    let a_url = ""

    if (!this.state.bookings.booking_filter && id) {
      a_url = "user/bookings/?bookingBranch=" + id
    } else if (this.state.bookings.booking_filter && id) {
      a_url =
        "user/bookings/?bookingBranch=" +
        id +
        "&bookingStatus=" +
        this.state.bookings.booking_filter
    } else if (this.state.bookings.booking_filter != null) {
      a_url = "user/bookings/?bookingStatus=" + state.booking_filter
    } else {
      a_url = "user/bookings/"
    }

    await http
      .get(`${a_url}`)
      .then(function (response) {
        let events = []
        response.data["results"].forEach((item) => {
          events.push(formatBooking(item))
        })
        const newEvents = orderBy(events, ["complete"], ["desc"])
        commit("SET_BOOKINGS", newEvents)
      })
      .catch(function (error) {
        console.log(error)
      })
  },
  async addBooking({ commit }, data) {
    const response = await http.post("user/bookings/", {
      bookingName: "Will Aid Appointment",
      bookingUser: data.user,
      bookingBranch: data.branch,
      bookingFirm: data.firm,
      bookingClient: null,
      bookingStartDate: data.start,
      bookingEndDate: data.start,
      bookingStartTime: data.startTime,
      bookingEndTime: data.endTime,
      bookingStatus: "Available",
    })
    commit("SET_ADD_BOOKING", formatBooking(response.data))
    Vue.prototype.$toast.success("Your Will Aid appointment has been created.")
  },
  async updateBooking({ dispatch }, update) {
    const response = await http.patch(`user/bookings/${update.id}/`, {
      bookingStartDate: update.start,
      bookingEndDate: update.end,
      bookingStartTime: update.startTime,
      bookingEndTime: update.endTime,
      bookingStatus: update.status,
      bookingNotes: update.notes,
    })
    switch (update.status) {
      case "Booked":
        update.color = "secondary"
        break
      case "Cancelled":
        update.color = "greyDark"
        break
      default:
        update.color = "brand"
    }
    let st = response.data.bookingStartTime.slice(0, 5)
    let et = response.data.bookingEndTime.slice(0, 5)
    update.time = st
    update.start = response.data.bookingStartDate
    update.date = response.data.bookingStartDate
    update.end = response.data.bookingEndTime + " " + et
    update.editStartTime = st
    update.editEndTime = et
    update.complete = response.data.bookingComplete
    update.status = response.data.bookingStatus
    dispatch("fetchBookings")
    return response
  },
  async confirmBooking({ commit }, booking) {
    const { client } = booking
    delete booking.client
    booking.bookingPhone = client.clientPhone
    await http
      .post("clients/", client)
      .then(async (response) => {
        booking.bookingClient = response.data.clientId
        await http
          .patch(`bookings/${booking.bookingId}`, booking)
          .then(async (response) => {
            await http
              .get(`user/bookings/${response.data.bookingId}/`)
              .then(function (response) {
                commit("SET_EDIT_BOOKING", formatBooking(response.data))
              })
          })
      })
      .catch(function (error) {
        console.log(error.reponse.data)
      })
  },
  async deleteBooking({ commit, state }, id) {
    await http.delete(`user/bookings/${id}/`)
    const bookings = state.bookings.filter((booking) => booking.id !== id)
    commit("SET_BOOKINGS", bookings)
  },

  async filterBookings({ commit }, data) {
    if (data.category === null) {
      data.category = ""
    }

    let a_url = ""

    if (data.id) {
      a_url =
        "user/bookings/?bookingBranch=" +
        data.id +
        "&bookingStatus=" +
        data.category
    } else {
      a_url = "user/bookings/?bookingStatus=" + data.category
    }

    await http
      .get(`${a_url}`)
      .then(function (response) {
        let events = []
        response.data["results"].forEach((item) => {
          let st = item.bookingStartTime.slice(0, 5)
          let et = item.bookingEndTime.slice(0, 5)
          let appData = {}
          appData.id = item.bookingId
          appData.name = item.bookingName
          appData.client = item.bookingClient
          appData.phone = item.bookingPhone
          appData.branch = item.bookingBranch
          appData.start = item.bookingStartDate + " " + st
          appData.end = item.bookingEndDate + " " + et
          appData.editStartTime = st
          appData.editEndTime = et
          appData.date = item.bookingStartDate
          appData.time = item.bookingStartTime
          appData.complete = item.bookingComplete
          appData.booked = item.bookingComplete
            ? dayjs(item.bookingComplete).format("DD-MM-YYYY")
            : null
          appData.notes = item.bookingNotes
          appData.status = item.bookingStatus
          switch (appData.status) {
            case "Booked":
              appData.color = "secondary"
              break
            case "Cancelled":
              appData.color = "greyDark"
              break
            default:
              appData.color = "brand"
          }
          events.push(appData)
        })
        const newEvents = orderBy(events, ["complete"], ["desc"])
        commit("SET_BOOKINGS_UNFILTERED", newEvents)
        commit("SET_BOOKINGS", events)
        commit("SET_BOOKING_FILTER", data.category)
      })
      .catch(function (error) {
        console.log(error.reponse.data)
      })
  },
}

const mutations = {
  [SETUP_BOOKING_STATE]: (state) => Object.assign(state, getDefaultState()),
  [SET_BOOKINGS]: (state, events) => (state.bookings = events),
  [SET_BOOKINGS_UNFILTERED]: (state, events) =>
    (state.bookings_unfiltered = events),
  [SET_BOOKING_FILTER]: (state, b_filter) => (state.booking_filter = b_filter),
  [SET_ADD_BOOKING]: (state, booking) => {
    state.bookings.unshift(booking)
    state.bookings_unfiltered.unshift(booking)
  },
  [SET_REMOVE_BOOKING]: (state, id) =>
    (state.bookings = state.bookings.filter((booking) => booking.id !== id)),
  [SET_EDIT_BOOKING]: (state, update) => {
    let index = state.bookings.findIndex((booking) => booking.id === update.id)
    if (index !== -1) {
      state.bookings.splice(index, 1, update)
      state.bookings_unfiltered.splice(index, 1, update)
    }
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
