import activity from "@/store/modules/activity"
import auth from "@/store/modules/auth"
import bookings from "@/store/modules/booking"
import clients from "@/store/modules/client"
import donations from "@/store/modules/donation"
import enquiries from "@/store/modules/enquiry"
import league from "@/store/modules/league"
import notifications from "@/store/modules/notification"
import resources from "@/store/modules/resource"
import settings from "@/store/modules/setting"
import setup from "@/store/modules/setup"
import Vue from "vue"
import Vuex from "vuex"
import createPersistedState from "vuex-persistedstate"

// Load Vuex
Vue.use(Vuex)

// Create store
export default new Vuex.Store({
  modules: {
    activity,
    auth,
    bookings,
    clients,
    donations,
    enquiries,
    league,
    notifications,
    resources,
    settings,
    setup,
  },
  plugins: [
    createPersistedState({
      paths: ["auth", "setup", "settings", "activity", "donations"],
    }),
  ],
})
