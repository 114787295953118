// setup store
export const SETUP_STATE = "SETUP_STATE"
export const SETUP_FIRM_STATE = "SETUP_STATE"
export const SETUP_BRANCH_STATE = "SETUP_STATE"
export const SET_FIRM = "SET_FIRM"
export const SET_BRANCHES = "SET_BRANCHES"
export const SET_ERROR = "SET_ERROR"
export const SET_SOURCE = "SET_SOURCE"
export const SET_MAINTENANCE = "SET_MAINTENANCE"

// auth store
export const SETUP_AUTH_STATE = "SETUP_AUTH_STATE"
export const SET_LOGIN_CREDS = "SET_LOGIN_CREDS"
export const SET_TOKEN = "SET_TOKEN"
export const SET_AUTH_TIME = "SET_AUTH_TIME"
export const SET_VALID_EMAIL = "SET_VALID_EMAIL"
export const SET_SECRET = "SET_SECRET"
export const SET_OTP = "SET_OTP"
export const SET_USER = "SET_USER"
export const SET_HASH = "SET_HASH"
export const SET_LOGIN_STEP = "SET_LOGIN_STEP"
export const SET_VERIFY_FAIL = "SET_VERIFY_FAIL"

// activity store
export const SETUP_ACTIVITY_STATE = "SETUP_ACTIVITY_STATE"
export const SETUP_DONATIONS_STAT_STATE = "SETUP_DONATIONS_STAT_STATE"
export const SET_DONATIONS_STAT = "SET_DONATIONS_STAT"
export const SETUP_AVERAGE_GIFT_STATE = "SETUP_AVERAGE_GIFT_STATE"
export const SET_ACTIVITIES = "SET_ACTIVITIES"
export const SET_AVERAGE_GIFT = "SET_AVERAGE_GIFT"
export const SET_ENQUIRY_TOTALS = "SET_ENQUIRY_TOTALS"
export const SET_BOOKING_TOTALS = "SET_BOOKING_TOTALS"
export const SET_TOTALS = "SET_TOTALS"

// donations store
export const SETUP_DONATION = "SETUP_DONATION"
export const SET_DONATION = "SET_DONATION"

// bookings store
export const SETUP_BOOKING_STATE = "SETUP_BOOKING_STATE"
export const SET_BOOKINGS = "SET_BOOKINGS"
export const SET_BOOKINGS_UNFILTERED = "SET_BOOKINGS_UNFILTERED"
export const SET_BOOKING_FILTER = "SET_BOOKING_FILTER"
export const SET_ADD_BOOKING = "SET_ADD_BOOKING"
export const SET_REMOVE_BOOKING = "SET_REMOVE_BOOKINGY"
export const SET_EDIT_BOOKING = "SET_EDIT_BOOKING"

// clients store
export const SETUP_CLIENT_STATE = "SETUP_CLIENT_STATE"
export const SET_CLIENTS = "SET_CLIENTS"
export const SET_CLIENT = "SET_CLIENT"

// enquiries store
export const SETUP_ENQUIRY_STATE = "SETUP_ENQUIRY_STATE"
export const SET_ENQUIRIES = "SET_ENQUIRIES"
export const SET_ENQUIRY_FILTER = "SET_ENQUIRY_FILTER"
export const SET_ADD_ENQUIRY = "SET_ADD_ENQUIRY"
export const SET_REMOVE_ENQUIRY = "SET_REMOVE_ENQUIRY"
export const SET_EDIT_ENQUIRY = "SET_EDIT_ENQUIRY"
export const SET_ENQUIRY_MODAL = "SET_ENQUIRY_MODAL"

//notifications store
export const SETUP_NOTIFICATION_STATE = "SETUP_NOTIFICATION_STATE"
export const SET_NOTIFICATIONS = "SET_NOTIFICATIONS"
export const SET_LATEST_NOTIFICATIONS = "SET_LATEST_NOTIFICATIONS"
export const SET_NOTIFICATION_FILTER = "SET_NOTIFICATION_FILTER"
export const SET_ADD_NOTIFICATION = "SET_ADD_NOTIFICATION"
export const SET_DISMISS_NOTIFICATION = "SET_DISMISS_NOTIFICATION"
export const SET_EDIT_NOTIFICATION = "SET_EDIT_NOTIFICATION"

// resources store
export const SETUP_RESOURCE_STATE = "SETUP_RESOURCE_STATE"
export const SET_RESOURCES = "SET_RESOURCES"
export const SET_RESOURCE_FILTER = "SET_RESOURCE_FILTER"

// settings store
export const SETUP_SETTING_STATE = "SETUP_SETTING_STATE"
export const SET_BRANCH_SETTINGS = "SET_BRANCH_SETTINGS"
export const SET_DIARY_SETTINGS = "SET_DIARY_SETTINGS"
export const SET_DIARY_STATUS = "SET_DIARY_STATUS"
export const SET_DIARY_ONLINE = "SET_DIARY_ONLINE"
export const SET_DIARY_SYNC = "SET_DIARY_SYNC"
export const SET_EMAIL_SETTINGS = "SET_EMAIL_SETTINGS"
export const SET_EMAIL_FOLLOWUP = "SET_EMAIL_FOLLOWUP"
export const SET_EMAIL_SUBJECT = "SET_EMAIL_SUBJECT"
export const SET_EMAIL_MESSAGE = "SET_EMAIL_MESSAGE"

// league table store
export const SETUP_LEAGUETABLE_STATE = "SETUP_LEAGUETABLE_STATE"
export const SET_LEAGUEDATA = "SET_LEAGUEDATA"
