import "core-js/stable"
import "regenerator-runtime/runtime"
import Vue from "vue"
import router from "./router"
import store from "./store"
import vuetify from "./plugins/vuetify"
import toast from "./plugins/toast"
import Date from "./plugins/date"
import VueClipboard from "vue-clipboard2"
import browserDetect from "vue-browser-detect-plugin"
import App from "./App.vue"
import LogRocket from "logrocket"

LogRocket.init("u12uo2/will-aid-booking-system", {
  release: "2.1.0",
})

Vue.use(browserDetect)

Vue.use(Date)

VueClipboard.config.autoSetContainer = true

Vue.use(VueClipboard)

Vue.config.productionTip = false

Vue.filter("monDate", function (value) {
  if (value) {
    return Vue.prototype.$date(String(value)).format("MMMM YYYY")
  }
})

Vue.filter("longDate", function (value) {
  if (value) {
    return Vue.prototype.$date(String(value)).format("D MMMM YYYY")
  }
})

Vue.filter("shortDate", function (value) {
  if (value) {
    return Vue.prototype.$date(String(value)).format("DD-MM-YYYY")
  }
})

Vue.filter("time", function (value) {
  if (value) {
    let time = String(value)
    return time.substring(0, time.length - 10)
  }
})

let filter = function (text, length, clamp) {
  clamp = clamp || "..."
  let node = document.createElement("div")
  node.innerHTML = text
  let content = node.textContent
  return content.length > length ? content.slice(0, length) + clamp : content
}

Vue.filter("truncate", filter)

Vue.filter("capitalize", function (value) {
  if (!value) {
    return ""
  }
  let v = value.toString()
  return v.charAt(0).toUpperCase() + v.slice(1)
})

Vue.filter("thousands", function (value) {
  if (!value) {
    return
  }
  let newValue = Math.round(value)
  newValue = newValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  return newValue
})

new Vue({
  router,
  store,
  vuetify,
  toast,
  render: (h) => h(App),
}).$mount("#app")
